<template>
  <div>
    <b-row cols="12">
      <b-col cols="6">
        <h2
          v-if="title != ''"
          class="mr-1"
        >
          {{ title }}
        </h2>
      </b-col>
      <b-col
        cols="6"
        style="align-self: center; text-align: end;"
      >
        <slot name="buttonaddon" />
      </b-col>
      <b-col
        v-if="isFilter || title != ''"
        cols="12"
      >
        <hr>
      </b-col>
    </b-row>
    <b-row cols="12">
      <b-col
        md="6"
        class="my-1"
        cols="6"
      >
        <!-- <b-button
          v-if="isAdd"
          size="sm"
          variant="primary"
          @click="addForm"
        >
          <feather-icon icon="PlusIcon" />
          Tambah
        </b-button> -->
      </b-col>

      <b-col
        v-if="isFilter"
        md="6"
        class="my-1"
        cols="6"
      >
        <b-form-group
          label="Search "
          label-cols-sm="4"
          label-align-sm="right"
          label-for="filterInput"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filterInput"
              v-model="search"
              type="search"
              placeholder="Type to Search"
            />

            <slot name="buttonaddaction" />
            <b-button
              v-if="isAdd"
              class="ml-1"
              size="sm"
              variant="primary"
              @click="addForm"
            >
              <feather-icon icon="PlusIcon" />
              Tambah
            </b-button>
            <!-- <b-input-group-append>
              <b-button
                :disabled="!search"
                @click="search = ''"
              >
                Clear
              </b-button>
            </b-input-group-append> -->
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-overlay
          :show="loading"
          variant="light"
          :opacity="0.5"
          rounded="sm"
        >

          <b-table
            :hover="isHover"
            striped
            responsive
            :items="items"
            :fields="fieldsTable"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDirection"
            @row-clicked="onRowSelected"
          >
            <template #cell(_)="data">
              <!-- <span
                v-if="isView"
                class="mr-1"
              >
                <b-link @click="viewForm(data)">
                  <b-badge variant="success">
                    <feather-icon icon="FolderIcon" />
                  </b-badge>
                </b-link>
              </span> -->
              <b-button
                v-if="isView"
                size="sm"
                variant="primary"
                class="mr-1 btn-icon"
                @click="viewForm(data)"
              >
                <feather-icon icon="FolderIcon" />
              </b-button>
              <b-button
                v-if="isEdit"
                size="sm"
                variant="success"
                class="mr-1 btn-icon"
                @click="editForm(data)"
              >
                <feather-icon icon="EditIcon" />
              </b-button>
              <b-button
                v-if="isDelete"
                size="sm"
                variant="danger"
                class="mr-1 btn-icon"
                @click="confirmDelete(data)"
              >
                <feather-icon icon="TrashIcon" />
              </b-button>
              <!-- <span
                v-if="isEdit"
                class="mr-1"
              >
                <b-link @click="editForm(data)">
                  <b-badge variant="success">
                    <feather-icon icon="EditIcon" />
                  </b-badge>
                </b-link>
              </span> -->
              <!-- <span
                v-if="isDelete"
                class="mr-1"
              >
                <b-link @click="confirmDelete(data)">
                  <b-badge variant="danger">
                    <feather-icon icon="TrashIcon" />
                  </b-badge>
                </b-link>
              </span> -->
            </template>
            <template
              v-for="(_, slot) of $scopedSlots"
              v-slot:[slot]="scope"
            >
              <slot
                :name="slot"
                v-bind="scope"
              />
            </template>
          </b-table>
          <b-pagination
            v-if="isPagination"
            v-model="currentPage"
            :total-rows="recordsTotal"
            :per-page="pageSize"
            aria-controls="my-table"
          />
        </b-overlay>
      </b-col>
    </b-row>
    <!-- modal vertical center -->
    <b-modal
      id="modal-center"
      ref="my-modal-form"
      centered
      title="Form"
      hide-footer
      no-close-on-backdrop
    >
      <div><baseform
        :fields="fields"
        :posturl="dataurl"
        :dataitem="dataitem"
        :ismodal="true"
        @onsubmite="submitModal"
      /></div>
    </b-modal>
  </div>
</template>
<script>
import {
  BTable, BRow, BCol, BFormGroup, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton, BLink,BBadge,BOverlay,BModal
} from 'bootstrap-vue'
import baseform from '@/views/base/BaseForm.vue'
import { useEventListener } from '@vueuse/core'

export default {
  components: {
    BTable,
    BCol,
    BFormGroup,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BRow,
    // BLink,
    // BBadge,
    BOverlay,
    BModal,
    baseform
  },
  props: {
    isAction:{
      type: Boolean,
      default: true
    },
    isPagination:{
      type: Boolean,
      default: true
    },
    isHover:{
      type: Boolean,
      default: false,
    },
    isFilter: {
      type: Boolean,
      default: true,
    },
    isAdd: {
      type: Boolean,
      default: true,
    },
    isEdit: {
      type: Boolean,
      default: true,
    },
    isDelete: {
      type: Boolean,
      default: true,
    },
    isView: {
      type: Boolean,
      default: true,
    },
    isModal: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    baseroute: {
      type: String,
      default: '',
    },
    dataurl: {
      type: String,
      default: '',
    },
    fields: {
      type: Array,
      default() {
        return []
      },
    },
    fieldsform: {
      type: Array,
      default() {
        return []
      },
    },
    filter: {
      type: Array,
      default() {
        return []
      },
    },
    pageSize:{
      type:Number,
      default:10
    }
  },
  data() {
    return {
      currentPage: 1,
      sortBy: '',
      sortDirection: true,
      recordsTotal: 0,
      search: '',
      items:[],
      loading:false,
      dataitem:{},
      fieldsTable:[]
    }
  },
  watch: {
    search() {
      this.fetchData()
    },
    currentPage() {
      this.fetchData()
    },
    sortDirection() {
      this.fetchData()
    },
  },
  beforeMount(){
    let newobj=JSON.parse(JSON.stringify(this.fields))
    for (let index = 0; index < newobj.length; index++) {
      let row = newobj[index]
      if(row.type == 'select-ajax'){
        row.key = row.key+'.name'
      }
      this.fieldsTable.push(row) 
    }
    if(this.isAction)
      this.fieldsTable.push('_')

  },
  mounted() {
    if(this.dataurl != '')
      this.fetchData()
  },

  methods: {
    fetchData() {
      this.loading =true
      var params ={...this.filter}
      
      // set paging
      params["start"] = (this.currentPage-1) * this.pageSize 
      params["length"] = this.pageSize 
      
      // set sort
      if(this.sortBy == '')
        params["sort[created_at]"] = "desc" 
      else
        params["sort["+this.sortBy+"]"] = this.sortDirection ? 'asc' :'desc'

      // set search
      if(this.search != '')
        params["search"] = this.search

          
      this.$http.get(this.dataurl,{params:params})
        .then(res  => {
          this.items = res.data.data
          this.recordsTotal = res.data.recordsFiltered
          this.$emit('showData', res.data.data)
        })
        .catch(()=>{
          this.$bvToast.toast('Something wrong', {
            title: 'Fail',
            solid: true,
            variant:'warning'
          })
        })
        .finally(() => this.loading = false) 

    },
    submitModal(val) {
      if(val) this.fetchData()
      this.$refs['my-modal-form'].hide()
    },
    addForm(){
      if(this.isModal)
      this.$refs['my-modal-form'].show()
      else
      this.$router.push({ name: this.baseroute+'-create' })
      // this.dataitem ={}
    },
    editForm(data){
      this.$router.push({ name: this.baseroute+'-edit',params : { id: data.item.id} })

      // this.dataitem = data.item
      // this.$refs['my-modal-form'].show()
    },
    viewForm(data){
      this.$router.push({ name: this.baseroute+'-view',params : { id: data.item.id} })
    },
    confirmDelete(data){
      const {id} = data.item
      this.$bvModal
        .msgBoxConfirm('Are you sure delete '+Object.values(data.item)[1]+' ?', {
          title: 'Please Confirm',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
          okVariant:'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
        })
        .then(confirm => {
          if(confirm){
            this.$http.delete(this.dataurl+'/'+id)
              .then(() => {
                this.fetchData()

                  // this.items = data.data
                  // this.recordsTotal = data.recordsTotal
                  // this.loading =false
  
            })
          }
        })
    },
    onRowSelected(val){
      this.$emit("onRowSelected", val)
    },
    setDataTable(result){
      this.items = result
    }
    // onFiltered() {
    //   // console.log(filteredItems)
    //   // Trigger pagination to update the number of buttons/pages due to filtering
    //   // this.totalRows = filteredItems.length
    //   // this.currentPage = 1
    //   this.fetchData()
    // },
  },
}
</script>
