<template lang="">
  <div>
    <b-card>
      <basetable
        :dataurl="dataurl"
        :fields="fields"
        :baseroute="baseroute"
        :title="title"
        :is-view="false"
      />
    </b-card>
  </div>
</template>
<script>
import basetable from '@/views/base/BaseTable.vue'
import {BCard} from 'bootstrap-vue'
import config from './Config.js'

export default {
  components: {
    basetable,
    BCard
  },
  data() {
    return {
      title:config.title,
      dataurl:config.dataulr,
      baseroute:config.baseroute,
      fields:config.fields,
    }
  },
}
</script>
<style lang="">

</style>
